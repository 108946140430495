import MainFooter from "../MainFooter/MainFooter";
import Header from "../Header/Header";
import Promo from "../Promo/Promo";


function Main() {
  return (
    <main className="main">
      <Header/>
      <Promo/>
      <MainFooter/>
    </main>
  );
}

export default Main;
