// import logo from '../../images/logo-house1.png';
// import logo2 from '../../images/logo-var2.svg';
import logo3 from '../../images/Logo_broker-01.png'

function Logo() {
  return (
    <section className='logo'>
      <img src={logo3} alt="Логотип Компании" className='logo__pic'></img>
      {/* <div className='logo-text'> */}
      {/* <h2 className='logo-text__title'>broker</h2> */}
        {/* <h2 className='logo-text__title'><span className='logo__first-letter'>A</span>r<span className='logo__first-letter accent'>B</span>roker</h2> */}
        {/* <p className='logo-text__subtitle'>начните здесь воплощать мечту в реальность</p> */}
      {/* </div> */}
    </section>
  );
}

export default Logo;