import sber from '../../images/sber.png'
import sovkombank from '../../images/sovkombank.png'
import zenit from '../../images/zenit.png'
import vtb from '../../images/vtb.png'
import uralsib from '../../images/uralsib.png'
import gazprombank from '../../images/gazprombank.png'
import rosbank from '../../images/rosbank.png'
import mkb from '../../images/mkb.png'
import rosselhoz from '../../images/rosselhoz1.png'
import alfa from '../../images/alfa.png'
import otkritie from '../../images/otkritie.png'
import rnkb from '../../images/rnkb.png'

function Partners() {
  return (
    <section className='partners'>
    <h2 className='partners__title'>Наши партнеры:</h2>
    <div className='partners__list'>
      <img src={sber} alt="Значок Сбербанка" className='partners__item'></img>
      <img src={sovkombank} alt="Значок Совкомбанка" className='partners__item'></img>
      <img src={zenit} alt="Значок банка Зенит" className='partners__item'></img>
      <img src={vtb} alt="Значок банка ВТБ" className='partners__item'></img>
      <img src={uralsib} alt="Значок банка Уралсиб" className='partners__item'></img>
      <img src={gazprombank} alt="Значок Газпромбанка" className='partners__item'></img>
      <img src={rosbank} alt="Значок Росбанка" className='partners__item'></img>
      <img src={mkb} alt="Значок банка МКБ" className='partners__item'></img>
      <img src={rosselhoz} alt="Значок Россельхозбанка" className='partners__item'></img>
      <img src={alfa} alt="Значок Альфабанка" className='partners__item'></img>
      <img src={otkritie} alt="Значок банка Открытие" className='partners__item'></img>
      <img src={rnkb} alt="Значок РНКБ банка" className='partners__item'></img>
    </div>
  </section>
  );
}

export default Partners;