import ContactForm from "../ContactForm/ContactForm";

function RequestBlock({ onRequestSubmit }) {
  return (
    <div className='request-block' id='request'>
        <h2 className='request-block__title'>Заполните эту форму и мы перезвоним Вам в течение десяти минут</h2>
        <ContactForm
          onSubmit={onRequestSubmit}
        />
    </div>
  );
}

export default RequestBlock;