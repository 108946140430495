import { useState } from "react";
import { useFormWithValidation } from "../../hooks/useFormWithValidation";
import Hovered from "../Hovered/Hovered";
import RequestError from "../RequestError/RequestError";

function Subscription({ onSubscriptionSubmit }) {
  const [submitButtonText, setSubmitButtonText] = useState("Подписаться");
  const [requestError, setRequestError] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const { values, handleInputChange, isValid } =
    useFormWithValidation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormDisabled(true);
    setSubmitButtonText("Подписываем...");
    onSubscriptionSubmit({ email: values.email })
      .then(() => {
        setSubmitButtonText("Вы подписаны");
      })
      .catch((err) => {
        setRequestError(err);
        setIsFormDisabled(false);
        setSubmitButtonText("Подписаться");
        console.log(err);
      });
  };

  return (
    <section className='subscription'>
      <div className='subscription__text-container'>
        <h2 className='subscription__text-container-title'>Введите свой электронный почтовый адрес</h2>
        <p className='subscription__text-container-subtitle'>Чтобы всегда быть в курсе новостей нашей компании, 
        достаточно оставить почтовый адрес и Вы будете включены в нашу регулярную рассылку.</p>
      </div>
      <form className='subscription__form-container' onSubmit={handleSubmit}>
        <input 
          className='subscription__form-container-input'
          placeholder='Введите ваш e-mail'
          id="email"
          type="email"
          value={values.email || ""}
          onChange={handleInputChange}
          name="email"
          minLength="5"
          maxLength="30"
          disabled={isFormDisabled}
        >
        </input>
        <RequestError
        className="subscription__form__request-error"
        errorText={requestError}
      />
        <Hovered>
          <button className={`subscription__form-container-button${
            !isValid || isFormDisabled ? " subscription__form-container-button_disabled" : ""}`}
          >
            {submitButtonText}
          </button>
        </Hovered>
      </form>
    </section>
  );
}

export default Subscription;