/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Hovered from '../Hovered/Hovered';
  
const Social = ({ isVisible }) => {
  
    return (
    <section className={`social${ isVisible ? ' social_visible' : ''}`}>
      {/* <Hovered>
        <a className={`social__mark${ isVisible ? ' social__mark_visible' : ''} social__phone-mark`}>href="https://msngr.link/tg/PJuuLie"</a>
      </Hovered> */}
      <Hovered>
        <a className={`social__mark${ isVisible ? ' social__mark_visible' : ''} social__whatsapp-mark`} href="https://wa.me/+79259609961" target='blank'></a>
      </Hovered>
      <Hovered>
        <a className={`social__mark${ isVisible ? ' social__mark_visible' : ''} social__telegram-mark`} href="tg://resolve?domain=<PJuuLie>" target='blank'></a>
      </Hovered>
      {/* <Hovered>
        <a className={`social__mark${ isVisible ? ' social__mark_visible' : ''} social__viber-mark`} href="viber://chat?number=%2B79267922289" target='blank'></a>
      </Hovered> */}
    </section>
  );
}
  
export default Social;
