import Hovered from "../Hovered/Hovered";
import { HashLink as Link } from "react-router-hash-link";

function Navigation({ isBurgerMenuOpened, handleBurgerClose}) {

  const handleBackgroundClick = (e) => {
    if (e.target.className === 'navigation__background') {
      handleBurgerClose();
    }
  }


  return (
    <nav id="nav" className={`navigation${isBurgerMenuOpened ? " navigation_burger-opened" : ""}`}>
      <div className='navigation__background' onClick={handleBackgroundClick}>
        <Hovered>
          <button id="cross" className='navigation__close-button' onClick={handleBurgerClose}></button>
        </Hovered>
        <div className='navigation__container'>
          <Hovered>
            <Link to="#about" className={"navigation__item"} onClick={handleBurgerClose}>
              О компании
            </Link>
          </Hovered>
          <Hovered>
            <Link to="#services" className={"navigation__item"} onClick={handleBurgerClose}>
              Услуги
            </Link>
          </Hovered>
          <Hovered>
            <Link to="#contacts" className={"navigation__item"} onClick={handleBurgerClose}>
              Контакты
            </Link>
          </Hovered>
        </div>
      </div>
      
    </nav>
  );
}

export default Navigation;
