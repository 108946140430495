import RequestBlock from '../RequestBlock/RequestBlock'
import Partners from '../Partners/Partners'

function Sundry({ onRequestSubmit }) {
  return (
    <section className='sundry'>
      <Partners/>
      <RequestBlock
        onRequestSubmit={onRequestSubmit}
      />
    </section>
  );
}

export default Sundry;