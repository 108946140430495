import Hovered from "../Hovered/Hovered";

function Promo() {
  return (
    <section className='promo'>
      <h1 className="promo__title">
        Агентство недвижимости Ar<span className='accent'>B</span>roker
      </h1>
      <h2 className="promo__subtitle">
        Ипотека без первоначального взноса доступна всем!
      </h2>
      <div className='promo__buttons'>
        <Hovered>
          <button className='promo__button-style promo__left-button'><a className='promo-button__link' href='#request'>получить консультацию</a></button>
          <button className='promo__button-style promo__right-button'><a className='promo-button__link' href='#services'>подробнее об услугах</a></button>
        </Hovered>
      </div>
    </section>
  );
} 

export default Promo;