import noFee from '../../images/wallet.png'
import refinancingSymbol from '../../images/calculator4.png'
import buildingSymbol from '../../images/building.png'
import placeSymbol from '../../images/house.png'
import rentSymbol from '../../images/rent-house1.png'
import mortgageSymbol from '../../images/house-key.png'

function Services() {
  return (
    <section className='services' id="services">
      <h2 className='services__title'>Наши услуги:</h2>
      <div className="services__cards-list">
        <article className='services__card services__card-accent'>
          <img src={noFee} alt="Значок ипотеки без взноса" className='services__pic'></img>
          <h2 className="services__card-title">Ипотека без первоначального взноса</h2>
          <p className="services__card-text services__card-text_yellow">Больше нет необходимости копить деньги на первоначальный взнос</p>
          <p className="services__card-text">Мы разработали собственный механизм, позволяющий обойтись без него. Работаем без подтверждения дохода.</p>
        </article>
        <article className='services__card'>
        <img src={refinancingSymbol} alt="Значок рефинансирования" className='services__pic'></img>
        <h2 className="services__card-title">Рефинансирование</h2>
        <p className="services__card-text services__card-text_yellow">Рефинансирование поможет сократить ежемесячные выплаты</p>
        <p className="services__card-text">С пополнением в семье можно претендовать и на изменение условий ипотечного кредитования, 
          теперь Вам доступна программа «Семейная ипотека под 6%». 
          Сменили место работы и сейчас трудитесь в аккредитованной IT-компании - можно рассчитывать на изменение условий ипотечного займа 
          с господдержкой по программе «Ипотека для IT-специалистов под 5%».</p>
        </article>
        <article className='services__card services__card-accent'>
        <img src={rentSymbol} alt="Значок аренды" className='services__pic'></img>
        <h2 className="services__card-title">Земельные участки</h2>
        <p className="services__card-text services__card-text_yellow">
          За последние годы было внесено много изменений в правовой политике землевладения и землепользования.
        </p>
        <p className="services__card-text">
          Высокий спрос сохраняется на земли промышленного и сельхозназначения, под многоэтажную жилую застройку, ИЖС, СНТ, ЛПХ, ДНП. 
          В большинстве случаев доступно изменение вида разрешенного использования, ГПЗУ, ПЗЗ и т.д.
        </p>
        </article>
        
        <article className='services__card'>
        <img src={placeSymbol} alt="Значок загородного дома" className='services__pic'></img>
        <h2 className="services__card-title">Загородные дома</h2>
        <p className="services__card-text services__card-text_yellow">
          Актуальна программа льготного ипотечного кредитования под 7,3% до 12 млн. руб. 
          на строительство частного дома самостоятельно или по договору подряда.
        </p>
        <p className="services__card-text">
          Строить можно на уже имеющемся земельном участке, либо взять ипотечный кредит на одновременное приобретение участка с  последующим  строительством на нем. 
          Иметь другое жилье для обеспечения данного займа не нужно.
        </p>
        </article>
        <article className='services__card services__card-accent'>
        <img src={buildingSymbol} alt="Значок новостройки" className='services__pic'></img>
        <h2 className="services__card-title">Новостройки</h2>
        <p className="services__card-text services__card-text_yellow">Мы являемся официальными партнерами большей части девелоперов Москвы и Московской области.</p>
        <p className="services__card-text">Комиссию за покупку оплачивает застройщик, сохраняя все скидки и привилегии для покупателей.</p>
        </article>
        <article className='services__card'>
        <img src={mortgageSymbol} alt="Значок ипотеки" className='services__pic'></img>
        <h2 className="services__card-title">Одобрение ипотеки</h2>
        <p className="services__card-text services__card-text_yellow">Одобрение ипотеки - задача не из легких для тех, у кого есть или были проблемы с выплатами в срок - просрочки. </p>
        <p className="services__card-text">Просрочки делят на категории, не со всеми из них можно работать. Требуется глубокий анализ состояния на период одобрения. 
          Только профессиональный брокер может выявить скрытые причины постоянных отказов в банках.</p>
        </article>
      </div>
    </section>
  );
}

export default Services;