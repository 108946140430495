//import {Route} from "react-router-dom";
import vk from '../../images/footer-vk.png'
import telegram from '../../images/footer-telegram.png'
import whatsApp from '../../images/footer-whatsApp.png'
import Hovered from '../Hovered/Hovered';

function Footer() {
  return (
    <footer className="footer" id="contacts">
      <div className="footer__contacts">
        <a className="footer__title footer__title-mail" href="mailto:Snnedvigimost@mail.ru">Почта: arbroker@mail.ru</a>
        <a className="footer__title footer__title-phone" href='tel:+79267922289'>Телефон: +7 (495) 744-69-60</a>
        <a href='https://yandex.ru/maps/213/moscow/house/yelokhovskiy_proyezd_1/Z04YcABjTEMDQFtvfXt2c3pkbQ==/?ll=37.673564%2C55.772679&z=17.09'target='_blank' rel='noopener noreferrer' className="footer__title footer__title-address">
          Адрес: г. Москва, Елоховский проезд, д.1
        </a>
      </div>
      <div className="footer__social">
        <p className='footer__social-text'>мы в соцсетях:</p>
        <div className='footer__social-container'>
          <Hovered>
            <a href='https://vk.com/public218553383' target='_blank' rel='noopener noreferrer' className='footer__social-container-link'>
              <img src={vk} alt="Значок Вконтакте" className='footer__social-container-item'></img>
            </a>
            <a href='https://t.me/ipotekadlyavseh' target='_blank' rel='noopener noreferrer' className='footer__social-container-link'>
              <img src={telegram} alt="Значок Телеграм" className='footer__social-container-item'></img>
            </a>
            <a href='#' target='_blank' rel='noopener noreferrer' className='footer__social-container-link'>
              <img src={whatsApp} alt="Значок Вотсапа" className='footer__social-container-item'></img>
            </a>
            
          </Hovered>
        </div> 
      </div>
    </footer>
  );
}

export default Footer;
