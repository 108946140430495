import {API_URLS} from "./constants.js";

class Api {
  constructor(options) {
    this._url = options.BASE_URL;
  }

  _makeRequest({url, method, contentType, body}) {
    const requestInfo = {
      headers: {},
    };

    if (method !== undefined) {
      requestInfo.method = method;
    }

    if (contentType !== undefined) {
      requestInfo.headers['Content-Type'] = contentType;
    }

    if (body !== undefined) {
      requestInfo.body = body;
    }

    return fetch(url, requestInfo)
      .then(res => res.ok
          ? res.json()
          : res.json().then((resJson) => Promise.reject(resJson.message))
      );
  }

  addClient(data) {
    return this._makeRequest({
      url: `${this._url}/clients`,
      method: 'POST',
      contentType: 'application/json',
      body: JSON.stringify(data),
    });
  }

  addMail(data) {
    return this._makeRequest({
      url: `${this._url}/emails`,
      method: 'POST',
      contentType: 'application/json',
      body: JSON.stringify(data),
    });
  }
}

const api = new Api(API_URLS);

export default api;