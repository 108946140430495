import { useState } from "react";
import Hovered from "../Hovered/Hovered";
import Logo from "../Logo/Logo";
import Navigation from "../Navigation/Navigation";


function Header() {
  const [isBurgerMenuOpened, setIsBurgerMenuOpened] = useState(false);

  const handleBurgerOpen = () => {
    setIsBurgerMenuOpened(true);
  };

  const handleBurgerClose = () => {
    setIsBurgerMenuOpened(false);
  };

  return (
    <header className="header">
      <Logo/>
      <div className='header__menu'>
        <a className='header__number' href='tel:+74957408495'>+7 (495) 744-69-60</a>
        <Navigation
          isBurgerMenuOpened={isBurgerMenuOpened}
          handleBurgerClose={handleBurgerClose}
        />
        <Hovered>
          <button className={`header__burger-button${isBurgerMenuOpened ? " header__burger-button_type-hidden" : ""}`}
            onClick={handleBurgerOpen}></button>
        </Hovered>
      </div>
    </header>
  );
}

export default Header;
