import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import About from "../About/About";
import Services from "../Services/Services";
import Stages from "../Stages/Stages";
import Sundry from "../Sundry/Sundry";
import Subscription from "../Subscription/Subscription";
import api from "../../utils/Api";
import { INPUT_ERRORS } from "../../utils/constants";
import ScrollButton from '../ScrollButton/ScrollButton';
import Chat from "../Chat/Chat";

function App() {

  const handleRequestSubmit = ({name, phone, email}) => {
    if (!name || !phone) {
      console.log("Заполнены не все поля")
      return Promise.reject(INPUT_ERRORS.EMPTY_FIELDS);
    }
    return api.addClient({name, phone, email});
  }

  const handleSubscriptionSubmit = ({email}) => {
    if (!email) {
      console.log("Заполнены не все поля")
      return Promise.reject(INPUT_ERRORS.EMPTY_FIELDS);
    }
    return api.addMail({email});
  }

  return (
      <div className="app">
        <div className="page">
          <Main/>
          <About/> 
          <Services/>
          <Stages/>
          <Sundry
            onRequestSubmit={handleRequestSubmit}
          />
          <Subscription
            onSubscriptionSubmit={handleSubscriptionSubmit}
          />
          <Footer/>
          <ScrollButton/>
          <Chat />
        </div>
      </div>
  );
}

export default App;

