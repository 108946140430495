import { useState } from 'react';
import Hovered from '../Hovered/Hovered';
import Social from '../Social/Social';

function Chat() {

  const [isVisible, setIsVisible] = useState(false);

  const handleChatClick = () => {
    setIsVisible(!isVisible);
  }

  return (
    <section className="chat">
      <Hovered>
        <button onClick={handleChatClick} className='chat__mark'><a href="https://msngr.link/tg/PJuuLie"> </a></button>
      </Hovered>
      <Social 
        isVisible={isVisible}/>
    </section>
  );
}

export default Chat;
