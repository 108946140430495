import incomingCall from '../../images/stages-1.png'
import outgoingCall from '../../images/stages-2.png'
import analysis from '../../images/stages-3.png'
import actionPlan from '../../images/stages-4.png'
import propertySelection from '../../images/stages-5.png'
import deal from '../../images/stages-6.png'
import happy from '../../images/stages-7.png'
import bringFriend from '../../images/stages-8.png'

function Stages() {
  return (
    <section className='stages'>
      <h2 className='stages__title'>Этапы взаимодействия:</h2>
      <div className="stages__cards-list">
        <article className='stages__card'>
          <div className='stages__number'>1.</div>
          <img src={incomingCall} alt="Значок входящего обращения" className='stages__pic'></img>
          <h2 className="stages__card-title">Входящее обращение</h2>
          <p className="stages__card-text">Вы оставляете завку на бесплатную консультацию или звоните по единому номеру телефона компании</p>
        </article>
        <article className='stages__card'>
          <div className='stages__number'>2.</div>
          <img src={outgoingCall} alt="Значок входящего обращения" className='stages__pic'></img>
          <h2 className="stages__card-title">Обратная связь</h2>
          <p className="stages__card-text">Наши менеджеры перезвонят Вам в течение 10 минут с момента получения заявки</p>
        </article>
        <article className='stages__card'>
          <div className='stages__number'>3.</div>
          <img src={analysis} alt="Значок анализа ситуации" className='stages__pic'></img>
          <h2 className="stages__card-title">Анализ ситуации</h2>
          <p className="stages__card-text">Потребуется провести глубокий анализ в сложившихся обстоятельствах. Запрашиваем документы.</p>
        </article>
        <article className='stages__card'>
          <div className='stages__number'>4.</div>
          <img src={actionPlan} alt="Значок анализа ситуации" className='stages__pic'></img>
          <h2 className="stages__card-title">План действий</h2>
          <p className="stages__card-text">Специалист подробно расскажет обо всех возможных вариантах решения вопроса. Выбрать наиболее комфортный - Ваша задача.</p>
        </article>
        <article className='stages__card'>
          <div className='stages__number'>5.</div>
          <img src={propertySelection} alt="Значок подбора недвижимости" className='stages__pic'></img>
          <h2 className="stages__card-title">Подбор недвижимости</h2>
          <p className="stages__card-text">Вы оставляете завку на бесплатную консультацию или звоните по единому номеру телефона компании</p>
        </article>
        <article className='stages__card'>
          <div className='stages__number'>6.</div>
          <img src={deal} alt="Значок сделки" className='stages__pic'></img>
          <h2 className="stages__card-title">Сделка</h2>
          <p className="stages__card-text">Мы подготовим Вас к сделке морально и документально. Процесс не окажется стрессом. С нами легко! Полное юридическое и физическое сопровождение.</p>
        </article>
        <article className='stages__card'>
          <div className='stages__number'>7.</div>
          <img src={happy} alt="Значок счастья" className='stages__pic'></img>
          <h2 className="stages__card-title">Счастье</h2>
          <p className="stages__card-text">О, это прекрасное чувство! Будем несказанно рады запечатлеть Вашу улыбку на совместном фото, а каждому покупателю дарим вкуснейшее кондитерское изделие!</p>
        </article>
        <article className='stages__card'>
          <div className='stages__number'>8.</div>
          <img src={bringFriend} alt="Значок приведи друга" className='stages__pic'></img>
          <h2 className="stages__card-title">Приведи друга!</h2>
          <p className="stages__card-text">За каждого приведенного Вами клиента наша компания гарантирует бонус.</p>
        </article>
      </div>
    </section>
  );
}

export default Stages;