import Chat from "../Chat/Chat";


function MainFooter() {

  return (
    <div className='main-footer'>
      <div className='main-footer__container'>
        <p className='main-footer__text'>листайте вниз</p>
        <div className='main-footer__scroll-down'></div>
      </div>
      <div className="main-footer__advantages">
        <p className='main-footer__advantages-text'>Никаких предоплат</p>
        <p className='main-footer__advantages-text'>Минимальная % ставка</p>
        <p className='main-footer__advantages-text'>Рассмотрение заявки менее 10 минут</p>
      </div>
      <div className='main-footer__empty-container'></div>
    </div>
  );
}

export default MainFooter;