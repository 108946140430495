import photoAbout from '../../images/photo-about.png';

function About() {
  return (
    <section className='about' id="about">
      <article className='about__article'>
        <h2 className='about__title'>Мы – компания Ar<span className='accent'>B</span>roker</h2>
        <p className='about__text'>Наша профессиональная сфера – недвижимость и все, что с ней связано. 
          Предоставляем широкий комплекс брокерских и риэлтерских услуг.
          Весь цикл в одних руках!
        </p>
        <p className='about__text'>Мы ведем Вас от консультаций до завершающего этапа сделки по покупке собственной недвижимости, 
          не исключая сложных ситуаций.
          В условиях сегодняшнего рынка для Вас это – самый удобный способ контролировать и наблюдать весь процесс.
        </p>
        <p className='about__text'>Ar<span className='accent'>B</span>roker – это команда профессиональных юристов, риэлтеров и брокеров.
          Опыт работы каждого сотрудника в сфере недвижимости от 10 лет. 
          Это значит, что решением Ваших вопросов будут заниматься квалифицированные специалисты, доказавшие свои умения на практике. 
          У каждого из наших клиентов свой, особенный путь и мы уважаем индивидуальность выбора. Очень важно, делая этот выбор, не получить очередную порцию стресса. 
          Именно для этого мы, группа единомышленников, объединились в команду.
        </p>
      </article>
      <img src={photoAbout} alt="Фото Профиля Компании" className='about__pic'></img>
    </section>
  );
}

export default About;